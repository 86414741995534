// ---Dependencies
import { type ReactElement } from 'react';
import Head from 'next/head';
// ---Components
import { LangHomeCont } from 'Cont/Landing/LangHomeCont/LangHomeCont';
// ---Config
import { PROD_FRONT_URL } from 'AppConfig/globalConfig';
import { locales } from 'Cont/Landing/LangHomeCont/locales/locales';
import { SUPPORTED_LANGUAGES, type ISupportedLanguage } from 'src/shared/constants/language';

/**
 * Componente LangHomePage: Contenedor principal para la ruta '/langhome' componente es para dar inyectar metadatos dela página con el contenedor de la página para el componente "LangHome"
 * @returns { ReactElement } ReactElement
 */
export default function LangHomePage(): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const lang: ISupportedLanguage = 'english';
  const { withRegion } = SUPPORTED_LANGUAGES[lang];
  const { metaInfo } = locales[lang];
  const { title, description } = metaInfo;
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${PROD_FRONT_URL}/images/icons/just-logo.webp`} />
        <meta name="twitter:image" content={`${PROD_FRONT_URL}/images/icons/just-logo.webp`} />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <meta property="og:locale" content={withRegion} />

        <link rel="canonical" href={`${PROD_FRONT_URL}/`} />
        <meta property="og:url" content={`${PROD_FRONT_URL}/`} />
      </Head>
      <LangHomeCont />
    </>
  );
}
