// ---Dependencies
import { type ReactElement } from 'react';
// ---UI Dependencies
// ---Custom Hooks
// ---Config
// ---Assets
// ---Utils
// ---Requests
// ---Styles
import style from 'Cont/Landing/LangHomeCont/LangHomeCont.module.scss';
import { useSelectorBuilder } from 'src/utils/hooks/styles/useSelectorBuilder';
import { locales } from './locales/locales';
import { Img } from 'src/common/Img/Img';
import { Fcol, Frow } from 'react-forge-grid';
import { responsiveBasicGrid } from 'src/utils/functions/responsiveUtils';
import { GlowButton } from 'src/common/GlowButton/GlowButton';
import Link from 'next/link';
import { SimpleDivider } from 'src/common/SimpleDivider/SimpleDivider';
import { IoLanguageSharp } from 'react-icons/io5';
// ---CommonComponents
// ---Components
// ---Services

/**
 * LangHomeCont Component:  Descripcion del comportamiento...
 * @param {Props} props - Parametros del componente como: ...
 * @returns {ReactElement}
 */
export function LangHomeCont(): ReactElement {
  // -----------------------CONSTS, HOOKS, STATES
  const { classNames } = useSelectorBuilder(style.LangHomeCont);
  const { metaInfo, h1 } = locales.english;
  // -----------------------MAIN METHODS
  // -----------------------AUX METHODS
  // -----------------------RENDER
  return (
    <div className={classNames}>
      <h1>{h1}</h1>
      <p>{metaInfo.description}</p>
      <Img className="logoCont" src="/images/icons/just-logo.webp" />
      <Frow hAlign="center" vAlign="middle">
        <Fcol {...responsiveBasicGrid(20)}>
          <Link href="/en/">
            <GlowButton icon={<IoLanguageSharp />} glowVariant="pink">
              English
            </GlowButton>
          </Link>
        </Fcol>
        <Fcol {...responsiveBasicGrid(60)}>
          <SimpleDivider type="horizontal" variant="pink" />
        </Fcol>
        <Fcol {...responsiveBasicGrid(20)}>
          <Link href="/es/">
            <GlowButton icon={<IoLanguageSharp />} glowVariant="pink">
              Spanish
            </GlowButton>
          </Link>
        </Fcol>
      </Frow>
    </div>
  );
}
