export const locales = {
  english: {
    metaInfo: {
      title: 'Select your preferred language - ForgeMyTech.com',
      description: 'Get ready to revolutionize your business in your language of preference',
    },
    h1: 'Choose your language',
    exampleContainer: {
      someLabel: 'englishStuff',
    },
  },
  spanish: {
    exampleContainer: {
      someLabel: 'englishStuff',
    },
  },
} as const;

export type LocaleData = (typeof locales)['english'];
